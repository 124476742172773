@import '../colors.scss';
@import '../effects.scss';

.json-schema-form {
  margin-bottom: 20px;

  .form-group {
    margin-bottom: 20px;
    letter-spacing: normal;

    .control-label {
      font-weight: 300;
      margin-bottom: 5px;
    }

    legend {
      font-size: 1.3em;
      letter-spacing: 0.5px;
    }

    .array-item {
      margin-bottom: 30px;
      legend {
        text-transform: uppercase;
        font-size: 1em;
      }
    }
  }

  .btn {
    width: 100%;
    box-shadow:
      0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1),
      0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  }

  .btn-submit {
    color: #fff;
    @include animate('background');
  }

  .btn-add {
    background-color: $green;
    color: #fff;
    margin-top: 30px;
  }

  .btn-remove {
    background-color: $red;
    color: #fff;
  }

  #root__title {
    margin-bottom: 30px;
  }

  .field-boolean {
    .checkbox {
      span {
        margin-left: 8px;
        font-weight: 300;
        font-size: 0.9em;
      }
    }
  }

  .field-description {
    span {
      font-size: 0.9em;
      font-weight: 300;
    }

    img {
      margin-bottom: 20px;
      max-width: 100%;
    }
  }

  .field-radio-group {
    .radio {
      span {
        margin-left: 8px;
        font-weight: 300;
        font-size: 1em;
      }
    }
  }
}
