.tournament-page {
  background-color: #ffd700;
  height: 100%;
  min-height: 100vh;
  padding: 0 50px;

  .tournament-header {
    padding-top: 20px;
    text-align: center;

    img {
      max-width: 100%;
    }
  }

  .tournament-content {
    background-color: #fff;
    padding: 10px 30px;
    margin: 20px 0;
    border: 1px solid #ddd;
    position: relative;
  }

  .tournament-footer {
    padding: 10px 0;
    text-align: center;

    img {
      height: 40px;
    }
  }

  .tournament-menu {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;
      margin: 10px;
    }

    button {
      background-color: #fff;
      padding: 5px 15px;
      font-weight: 500;
      border: 2px solid #000;
      width: 110px;
    }

    .red {
      border-color: #e4263c;
      color: #e4263c;
    }

    .red.selected {
      color: #fff;
      background-color: #e4263c;
    }

    .orange {
      border-color: #f29425;
      color: #f29425;
    }

    .orange.selected {
      color: #fff;
      background-color: #f29425;
    }

    .yellow {
      border-color: #f9da01;
      color: #f9da01;
    }

    .yellow.selected {
      color: #fff;
      background-color: #f9da01;
    }

    .green {
      border-color: #90be44;
      color: #90be44;
    }

    .green.selected {
      color: #fff;
      background-color: #90be44;
    }

    .blue {
      border-color: #2b4acf;
      color: #2b4acf;
    }

    .blue.selected {
      color: #fff;
      background-color: #2b4acf;
    }

    .violet {
      border-color: #8850c4;
      color: #8850c4;
    }

    .violet.selected {
      color: #fff;
      background-color: #8850c4;
    }
  }
}

.tournament-team {
  position: fixed;
  bottom: 40px;
  right: 0;
  z-index: 2;

  .settings-text {
    margin-top: 5px;
    background-color: #003a8a;
    color: #fff;
    text-align: center;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    font-weight: 500;
    font-size: 0.9em;
    padding: 10px 25px;
    cursor: pointer;
  }
}

.tournament-settings {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 3;
  display: none;

  .settings-container {
    background-color: #fff;
    width: 80%;
    max-width: 350px;
    margin: 10% auto;
    min-height: 350px;
    border: 2px solid #003a8a;
    box-shadow: 4px 4px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  .settings-header {
    background-color: #003a8a;
    color: #fff;
    padding: 5px 15px;
  }

  .settings-content {
    padding: 15px 15px;
  }

  &.modal-open {
    display: block;
  }

  .teams-select {
    p {
      margin-bottom: 5px;
    }

    select {
      width: 100%;
      padding: 5px 10px;
      border: 2px solid #003a8a;
      background-color: #fff;
      outline: none;
      color: #000;
    }
  }

  .filter-checkbox {
    margin-top: 20px;

    label {
      margin-left: 10px;
    }
  }

  .save-settings {
    margin-top: 125px;

    button {
      width: 100%;
      background-color: #ffd700;
      padding: 10px 0;
      border: 2px solid #003a8a;
      outline: none;
      color: #000;
    }
  }
}

@media (max-width: 768px) {
  .tournament-page {
    padding: 0 15px;

    .tournament-header {
      padding-top: 10px;
    }

    .tournament-content {
      background-color: #fff;
      padding: 5px;
    }
  }
}
