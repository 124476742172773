.payment-result {
  .payment-animation {
    margin-top: -30px;
  }

  .payment-message {
    font-size: 1.3em;
    text-align: center;
  }
}
