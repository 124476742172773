@import '../colors.scss';

.checkout {
  header {
    text-align: center;

    span {
      font-size: 1.4em;
      font-weight: 300;
    }
  }

  .payment-amount {
    text-align: center;
    margin-bottom: 20px;

    .amount-value {
      margin-top: -5px;
      font-size: 4em;
      font-weight: 600;
      letter-spacing: 1.5px;
    }

    .amount-currency {
      font-size: 1.2em;
      font-weight: 400;
    }
  }

  .payment-methods {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      //background-color: #f0f0f0;
      //border: 1px solid #ddd;
      box-shadow:
        0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
        0px 2px 5px 0px rgba(50, 50, 93, 0.1),
        0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
      border-radius: 7px;
      margin: 10px 0;
      padding: 8px 30px;
      text-align: center;
      position: relative;
      cursor: pointer;
      height: 46px;

      img {
        max-height: 30px;
        padding: 0 10px;
        width: auto;
      }

      &::before {
        background-color: #fff;
        width: 15px;
        height: 15px;
        display: block;
        content: '';
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 100%;
      }

      &.selected {
        background-color: #f5f5f5;
      }

      &.selected::before {
        background-color: #fff;
        box-shadow:
          0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
          0px 2px 5px 0px rgba(50, 50, 93, 0.1),
          0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
      }
    }

    // .satispay {
    //     background-color: #ef373f;
    // }

    // .paypal {
    //     background-color: #ffc43a;
    // }

    .cards {
      img {
        max-height: 25px;
      }
    }

    .applepay-logo {
      height: 24px;
    }
  }

  .payment-checkout {
    margin-top: 40px;
  }

  .test-environment {
    text-transform: uppercase;
    text-align: center;
    margin-top: 20px;
    color: $red;
  }
}
