.membership-page {
  .title {
    font-size: 1.5em;
    margin-bottom: 20px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .checkout-buttons {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
