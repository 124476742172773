.table-container {
  .table-row {
    display: flex;
    flex-wrap: wrap;
  }

  .table-cell {
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding-bottom: 20px;

    table {
      white-space: nowrap;
      border-collapse: collapse;
      width: 100%;
    }

    thead, tbody {
      width: 100%;
    }
  }

  .cell-50 {
    flex: 1 1 50%;
  }

  .cell-33 {
    flex: 1 1 33%;
  }

  .cell-12 {
    flex: 1 1 12%;
  }

  .cell-container {
    background-color: #fafafa;
    margin: 0 10px;
    border: 1px solid #d3d2c2;
    box-shadow: 5px 5px rgba(0, 0, 0, 0.2);
    height: 100%;
  }

  .cell-header {
    background-color: #003a8a;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.1em;
    padding: 5px 15px;

    border-bottom: 0;
  }

  .cell-content {
    padding: 20px;
    overflow-x: auto;
  }
}

.tournament-page {
  table {
    background-color: #fff;
    border: 1px solid #ddd;
    border-collapse: collapse;

    tr.selected {
      background-color: #ffd700;
    }

    td {
      border-top: 1px solid #ddd;
      padding: 3px 5px;
      text-align: center;
    }

    th {
      text-align: center;
      padding: 5px;
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #003a8a;
      color: #fff;
      border-top: 1px solid #ddd !important;
      border-bottom: 1px solid #ddd;
      text-transform: lowercase;
    }

    .odd {
      background-color: #f5f5f5;
    }

    .strong {
      font-weight: 800;
    }

    img {
      width: 20px;
    }
  }
}

.tournament-matches {
  overflow-x: auto;

  table {
    white-space: nowrap;
    border-collapse: collapse;
    width: 100%;
  }
}

.tournament-teams {
  .table-cell {
    padding-bottom: 30px;
  }

  .team-cell {
    background-color: #003a8a;
    border: 1px solid #ddd;
    color: #fff;
    margin: 0 5px;

    .team-name {
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
      padding-top: 10px;
    }

    .team-players {
      background-color: #fff;
      border: 1px solid #ddd;
      margin: 5px;
      color: #000;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        text-align: center;
        padding: 5px 0;
      }

      .referent {
        font-weight: 500;
      }
    }

    &.selected {
      background-color: #e4263c;
      color: #fff;
    }
  }
}

.tournament-maps {
  text-align: center;

  img {
    max-width: 100%;
    max-height: 750px; // TODO
  }
}

@media (max-width: 768px) {
  .table-container {
    .table-row {
      flex-direction: column;

      .table-cell {
        flex: 1 1 100%;
      }
    }

    .cell-content {
      padding: 10px;
    }
  }

  .tournament-teams {
    .table-cell {
      padding-bottom: 10px;
    }
  }
}
