@import '../effects';

.rainbow {
  padding: 40px 0;
  background-color: $red;
  @include animate('background');

  .page-container {
    margin: 0 auto;
    max-width: 768px;
    background-color: #fff;
    border-radius: 20px;
    min-height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
  }

  .page-header {
    padding-top: 20px;
    text-align: center;
  }

  .page-header img {
    max-width: 300px;
  }

  .page-content {
    padding: 30px 30px;
    flex: 1;
  }

  .page-footer {
    color: #fff;
    text-align: center;
    font-size: 0.8em;
    padding: 20px 0;

    p {
      margin: 0;
    }

    a {
      color: #fff;
    }
  }
}

@media screen and (max-width: 768px) {
  .rainbow {
    padding: 20px;
  }
}
