.module-page {
  .module-title {
    font-size: 1.5em;
    margin-bottom: 20px;
    font-weight: 400;
  }

  .module-completed-title {
    font-size: 1.4em;
    margin-bottom: 30px;
    font-weight: 400;
  }

  .module-initial-content {
    margin-bottom: 40px;
  }
}
